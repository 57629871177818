import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import type { Modifier } from "@popperjs/core";
import { Text } from "@ui/components/content/text/Text";
import { Money } from "@ui/components/data-display/money/Money";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
} from "@ui/components/overlay/popover/Popover";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";
import { getPDPSlug } from "@ui/utils/getPDPSlug";
import type { PlacementWithLogical } from "@ui/utils/theme-tools";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect, useState } from "react";
import type { CustomMarkerProps } from "./CustomMarker";
import { calculateLeft, calculateTop } from "./CustomMarker";
import { styles } from "./styles";

export const CustomMarkerProduct: FC<CustomMarkerProps> = (props: CustomMarkerProps) => {
    const { product, left, top } = props;
    const {
        name,
        articleNumber,
        colorCode,
        descriptiveHeading,
        image,
        priceRaw,
        listPriceRaw,
        discountPercentage,
    } = product;

    const { onToggle, onOpen, onClose, isOpen } = useDisclosure();
    const { get: getMicrocopy } = useMicrocopy();
    const { locale } = useRouter();
    const { lg } = useThemeMediaQuery();
    const [customInset, setCustomInset] = useState("");

    const placementY = top > 50 ? "top" : "bottom";
    const placementX = left > 33 ? "end" : "start";
    const placement: PlacementWithLogical =
        left > 33 && left < 66 ? placementY : `${placementY}-${placementX}`;

    useEffect(() => {
        if (placement === "bottom-start") {
            return setCustomInset("-50% auto auto 50%");
        }
        if (placement === "bottom-end") {
            return setCustomInset("-50% 50% auto auto");
        }
        if (placement === "bottom") {
            return setCustomInset("-50% auto auto auto");
        }
        if (placement === "top") {
            return setCustomInset("auto auto -50% auto");
        }
        if (placement === "top-start") {
            return setCustomInset("auto auto -50% 50%");
        }
        if (placement === "top-end") {
            return setCustomInset("auto 50% -50% auto");
        }
    }, [placement]);

    const customPosition: Modifier<"customPosition", any> = {
        name: "customPosition",
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: ({ state }) => {
            state.styles.popper.inset = customInset;
        },
        effect:
            ({ state }) =>
            () => {
                const popper = state.elements.popper;
                popper.style.inset = customInset;
            },
    };

    const viewProductMicrocopy =
        getMicrocopy(CommonCMS.global, CommonCMS.ariaLabels.viewProductAria) || "View product";

    return (
        <Popover
            isOpen={isOpen}
            onClose={onClose}
            placement={placement}
            returnFocusOnClose={false}
            modifiers={[customPosition]}
            isLazy
            flip={false}
            gutter={0}
            arrowPadding={0}
        >
            <PopoverTrigger>
                <Button
                    data-testid="popover-trigger-button"
                    aria-label="Toggle hotspot"
                    onMouseEnter={lg ? onOpen : undefined}
                    className="popover-trigger-button"
                    onClick={onToggle}
                    variant="unstyled"
                    sx={{
                        ...styles.marker,
                        border: "1px solid",
                        zIndex: 2,
                        borderColor: isOpen ? "white" : "rgba(255, 255, 255, 0.3)",
                        "::before": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: isOpen ? ["12px", "17.5px"] : ["24px", "35px"],
                            height: isOpen ? ["12px", "17.5px"] : ["24px", "35px"],
                            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "50%",
                            transition:
                                "width 0.25s ease-in, height 0.25s ease-in, border 0.25s ease-in",
                        },
                        ":hover::before": {
                            width: "17.5px",
                            height: "17.5px",
                        },
                    }}
                    h="100%"
                ></Button>
            </PopoverTrigger>
            <NextLink
                href={getPDPSlug(name, articleNumber, colorCode, locale).slug}
                aria-label={`${viewProductMicrocopy} ${name}`}
                position="absolute"
                w="100%"
                h="100%"
                onMouseLeave={onClose}
                sx={{
                    pointerEvents: isOpen ? "auto" : "none",
                    color: "black",
                    left: 0,
                    top: 0,
                }}
            >
                <PopoverContent
                    rootProps={{ style: { transform: "scale(0)" } }}
                    sx={{ ...styles.popoverContent, minW: ["100%", "345px"] }}
                >
                    <PopoverBody p={3} zIndex={3}>
                        <Flex justifyContent="flex-start" flexDirection="row" gap={2} flexGrow={1}>
                            {image && (
                                <Box sx={styles.popoverProductImage} backgroundColor={"gray.100"}>
                                    <NextImage sizes={["20vw"]} src={image} />
                                </Box>
                            )}
                            <Flex
                                justifyContent={"space-between"}
                                flexDirection={"column"}
                                w="100%"
                            >
                                <Flex flexDirection={"column"} w="100%">
                                    <Text sx={styles.popoverProductName} textTransform="capitalize">
                                        {name}
                                    </Text>
                                    {descriptiveHeading && (
                                        <Text
                                            sx={{
                                                ...styles.popoverDescription,
                                                color: "gray.500",
                                            }}
                                        >
                                            {descriptiveHeading}
                                        </Text>
                                    )}
                                </Flex>
                                {discountPercentage ? (
                                    <Flex flexDirection={"column"}>
                                        <Flex gap={2} alignItems={"center"} mb={2}>
                                            <Money
                                                fontSize={[
                                                    "desktopBodyTextTiny",
                                                    "desktopBodyTextSmall",
                                                ]}
                                                fontWeight="bold"
                                                locale={locale}
                                                data-testid="hotspotprice"
                                                data={priceRaw}
                                            />
                                            <Money
                                                fontWeight="bold"
                                                fontSize={[
                                                    "desktopBodyTextTiny",
                                                    "desktopBodyTextSmall",
                                                ]}
                                                color="gray.500"
                                                textDecoration="line-through"
                                                locale={locale}
                                                data-testid="hotspotrecommendedprice"
                                                data={listPriceRaw}
                                            />
                                        </Flex>
                                    </Flex>
                                ) : (
                                    <Money
                                        data={listPriceRaw}
                                        locale={locale}
                                        convertFromCents={true}
                                        fontWeight="semibold"
                                        data-testid="hotspotprice"
                                        fontSize={["desktopBodyTextTiny", "desktopBodyTextSmall"]}
                                    />
                                )}
                            </Flex>
                            <Flex>
                                <ECCOIcon transform={"rotate(-90deg)"} name="chevron" />
                            </Flex>
                        </Flex>
                    </PopoverBody>
                    <Box
                        onMouseLeave={onClose}
                        display={["none", "block"]}
                        position={"absolute"}
                        height="150%"
                        width="100%"
                        top={calculateTop(placementY)}
                        left={calculateLeft(placement)}
                    ></Box>
                </PopoverContent>
            </NextLink>
        </Popover>
    );
};
